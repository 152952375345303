import React, { FC } from "react"; 
import Select from "react-select";
import { useAppDispatch } from "legacy/store/typedHooks";
import { TranslationManager } from "translation-manager";
import textCodes from "legacy/constants/translations/textCodes.json";
import { setFilters } from "clean-archi/store/statistic";
import { BranchNameEnum } from "clean-archi/core/entities/statistics/StatisticsFilters";
import CustomDateRangePicker from "./CustomDateRangePicker";
import ChannelsFilter from "./ChannelsFilter";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";  
 
const StatisticsFilters: FC = () => {
    const dispatch = useAppDispatch();
 
    return (
        <div className="header">
            <div className="d-flex flex-row justify-content-between">
                <div className="filters-group d-flex flex-row align-items-center gap-2">
                    <CustomDateRangePicker />
                    <ChannelsFilter />
                </div>
                {process.env.REACT_APP_ENV !== "prod" && (
                    <div className="filters-group d-flex flex-row align-items-center">
                        <Select
                            defaultValue={{
                                label: TranslationManager.getText( textCodes.FILTERS_PROD_ENV ),
                                value: BranchNameEnum.PROD,
                            }}
                            options={[
                                {
                                    label: TranslationManager.getText(
                                        textCodes.FILTERS_PROD_ENV
                                    ),
                                    value: BranchNameEnum.PROD,
                                },
                                {
                                    label: TranslationManager.getText(
                                        textCodes.FILTERS_DEV_ENV
                                    ),
                                    value: BranchNameEnum.DEV,
                                },
                            ]}
                            onChange={( option ) =>
                                dispatch(
                                    setFilters({
                                        branchName: option?.value,
                                    })
                                )
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
export default StatisticsFilters;
