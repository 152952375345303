import { useAppSelector, useAppDispatch } from "legacy/store/typedHooks";
import {
    MisunderstandingApiResponse,
    MessageDistributionApiResponse,
    UniqueUsersApiResponse,
    WeeklyConversationsApiResponse,
    ConversationsPerHourApiResponse,
} from "clean-archi/core/entities/statistics/StatisticsState";

type ApiResponse =
  | MisunderstandingApiResponse
  | MessageDistributionApiResponse
  | UniqueUsersApiResponse
  | WeeklyConversationsApiResponse;

interface UseStatsDataProps {
  dataSelector: (
    state: any
  ) => ApiResponse[] | ConversationsPerHourApiResponse[];
  loadingSelector: ( state: any ) => boolean;
}

const useStatsData = ({ dataSelector, loadingSelector }: UseStatsDataProps ) => {
    const statsFilters = useAppSelector(( state ) => state.stats.filters );
    const selectedConfiguration = useAppSelector(
        ( state ) => state.app.selectedConfiguration
    );
    const loading: boolean = useAppSelector( loadingSelector );
    const storeData = useAppSelector( dataSelector );
    const dispatch = useAppDispatch();
    const ComponentsError = useAppSelector(( state ) => state.stats.componentsError );
  
    return { storeData, loading, dispatch, statsFilters, selectedConfiguration, ComponentsError };
};

export default useStatsData;
