import React, { useState } from "react";
import {
    DateRangePicker,
    DefinedRange, 
    defaultStaticRanges
} from "react-date-range"; 
import { graphColors } from "../config/chartsConfig";
import { setFilters } from "clean-archi/store/statistic";
import { useAppDispatch } from "legacy/store/typedHooks";
import { Dropdown } from "react-bootstrap";
import { TranslationManager } from "translation-manager";
import textCodes from "legacy/constants/translations/textCodes.json";
import dayjs from "clean-archi/dayjs";


function getMonday ( date: Date ) {
    return dayjs( date ).startOf( "isoWeek" ).toDate();
}

function getSunday ( date: Date ) {
    return dayjs( date ).endOf( "isoWeek" ).toDate();
}

const customStaticRanges = [
    {
        label: TranslationManager.getText( textCodes.THIS_WEEK ),
        range: () => ({
            startDate: getMonday( new Date()),
            endDate: getSunday( new Date()),
        }),
        isSelected ( range: any ) {
            const definedRange = this.range();
            return (
                dayjs( range.startDate ).isSame( definedRange.startDate, "day" ) &&
        dayjs( range.endDate ).isSame( definedRange.endDate, "day" )
            );
        },
    },
    {
        label: TranslationManager.getText( textCodes.LAST_WEEK ),
        range: () => ({
            startDate: getMonday( dayjs().subtract( 1, "week" ).toDate()),
            endDate: getSunday( dayjs().subtract( 1, "week" ).toDate()),
        }),
        isSelected ( range: any ) {
            const definedRange = this.range();
            return (
                dayjs( range.startDate ).isSame( definedRange.startDate, "day" ) &&
        dayjs( range.endDate ).isSame( definedRange.endDate, "day" )
            );
        },
    },
];
  
const combinedStaticRanges = defaultStaticRanges.map(( range ) => {
    if ( range.label === "This Week" ) {
        return {
            ...customStaticRanges[0],  
        };
    }
    if ( range.label === "Last Week" ) {
        return {
            ...customStaticRanges[1], 
        };
    }
    return range; 
});
function CustomDateRangePicker () {
    const dispatch = useAppDispatch();

    const [dateRange, setDateRange] = useState([
        {
            startDate: dayjs
                .utc( dayjs().format( "YYYY-MM-DD 00:00:00" ))
                .subtract( 7, "day" )
                .toDate(),
            endDate: dayjs.utc( dayjs().format( "YYYY-MM-DD 00:00:00" )).toDate(),
            key: "selection",
        },
    ]);
    const formatSelectedDateRange = () => {
        const { startDate, endDate } = dateRange[0];
        return `${dayjs( startDate ).format( "ddd D MMM , YYYY" )} - ${dayjs(
            endDate
        ).format( "ddd D MMM , YYYY" )}`;
    };
    const handleDateRangeChange = ( item: any ) => {
        const { startDate, endDate } = item.selection;
        setDateRange([
            {
                startDate: dayjs
                    .utc( dayjs( startDate ).format( "YYYY-MM-DD 00:00:00" ))
                    .toDate(),
                endDate: dayjs
                    .utc( dayjs( endDate ).format( "YYYY-MM-DD 00:00:00" ))
                    .toDate(),
                key: "selection",
            },
        ]);
        // Dispatching the startDate and endDate
        dispatch(
            setFilters({
                startDate: dayjs
                    .utc( dayjs( startDate ).format( "YYYY-MM-DD 00:00:00" ))
                    .toDate(),
                endDate: dayjs
                    .utc( dayjs( endDate ).format( "YYYY-MM-DD 00:00:00" ))
                    .toDate(),
            })
        );
    };
    return (
        <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="date-range-dropdown">
                <i className="fa fa-calendar-alt" style={{ marginRight: 5 }}></i>
                {formatSelectedDateRange()}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <div className="date-range-picker">
                    <div style={{ display: "flex" }}>
                        <DefinedRange
                            ranges={dateRange}
                            onChange={handleDateRangeChange}
                            staticRanges={combinedStaticRanges}
                        />
                        <DateRangePicker
                            className="small-calendar"
                            onChange={handleDateRangeChange}
                            moveRangeOnFirstSelection={false}
                            months={1}
                            ranges={dateRange}
                            direction="vertical"
                            calendarFocus="backwards"
                            rangeColors={[graphColors[0]]}
                            minDate={dayjs.utc( "2024-09-01" ).toDate()}
                            maxDate={dayjs.utc().toDate()}
                            weekStartsOn={1}
                        />
                    </div>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default CustomDateRangePicker;
    