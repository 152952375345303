import React from "react";
import { kpiColors } from "../../config/chartsConfig";
import FrameInfo from "../FrameInfo";
import { TranslationManager } from "translation-manager";
import textCodes from "legacy/constants/translations/textCodes.json";
import { useAppSelector } from "legacy/store/typedHooks";
   
const CommitmentRate = () => {
    const openingsCountData = useAppSelector(
        ( state ) => state.stats.openingsCount.data.total
    );
    const ComponentsError = useAppSelector(( state ) => state.stats.componentsError );
    const ConversationsStoreData = useAppSelector(
        ( state ) => state.stats.totalConversations
    ); 

    const conversationCount = ConversationsStoreData[0]?.conversationsCount; 
    const commitmentRate =
      conversationCount > 0
          ? ( conversationCount / openingsCountData ) * 100
          : 0;
    
    const infoNumberOfTransfers = {
        title: TranslationManager.getText( textCodes.COMMITEMENT_RATE ),
        number: commitmentRate ? `${commitmentRate.toFixed( 2 )} %` : "0 %",
        // percentage: "4%",
        icon: "las la-cube",
        iconColor: kpiColors.iconColor,
        iconBg: kpiColors.iconBg,
        errorType: ComponentsError["commitmentRateError"],
    }; 

    return <FrameInfo {...infoNumberOfTransfers} />;
};
export default CommitmentRate;
