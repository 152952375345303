import { StatisticsFilters } from "clean-archi/core/entities/statistics/StatisticsFilters";
import { StatisticGateway } from "clean-archi/core/interfaces/gateways/statistic/StatisticGateway";
import axiosInstance from "legacy/store/requests/axiosInstance";

export class HttpStatisticGateway implements StatisticGateway {
    async getChannelsRequest ( filters: StatisticsFilters ): Promise<any> {
        try {
            const response = await axiosInstance.get(
                "/api/stats-v2/stats/channels-list",
                {
                    params: filters,
                }
            );
            return response.data.data;
        } catch ( error: any ) {
            throw new Error( "No Data Available" ); 
        }
    }
    async getConversationPerHourRequest ( filters: StatisticsFilters ): Promise<any> {
        try {
            const response = await axiosInstance.get(
                "/api/stats-v2/stats/conversations/average-by-hour",
                {
                    params: filters,
                }
            );
            return response.data.data;
        } catch ( error: any ) {
            throw new Error( "No Data Available" ); 
        }
    }
    async getOpeningsCountRequest ( filters: StatisticsFilters ): Promise<any> {
        try {
            const response = await axiosInstance.get(
                "/api/stats-v2/stats/openings/count",
                {
                    params: filters,
                }
            );
            return response.data;
        } catch ( error: any ) {
            throw new Error( "No Data Available" ); 
        }
    }
    async getConversationsDailyCountRequest (
        filters: StatisticsFilters
    ): Promise<any> {
        try {
            const response = await axiosInstance.get(
                "/api/stats-v2/stats/conversations",
                {
                    params: filters,
                }
            );
            return response.data;
        } catch ( error: any ) {
            throw new Error( "No Data Available" ); 
        }
    }

    async getConversationDurationRequest (
        filters: StatisticsFilters
    ): Promise<any> {
        try {
            const response = await axiosInstance.get(
                "/api/stats-v2/stats/conversations/average-duration",
                { params: filters }
            );
            return response.data;
        } catch ( error: any ) {
            throw new Error( "No Data Available" ); 
        }
    }
    async getConversationsCountRequest ( filters: StatisticsFilters ): Promise<any> {
        try {
            delete filters.botId;
            const response = await axiosInstance.get(
                "/api/stats-v2/stats/conversations/count",
                {
                    params: filters,
                }
            );
            return response.data;
        } catch ( error: any ) {
            throw new Error( "No Data Available" ); 
        }
    }
    async getTotalConversationsRequest ( filters: StatisticsFilters ): Promise<any> {
        try {
            delete filters.botId;
            const response = await axiosInstance.get(
                "/api/stats-v2/stats/conversations/count",
                {
                    params: filters,
                }
            );
            return response.data;
        } catch ( error: any ) {
            throw new Error( "No Data Available" ); 
        }
    }

    async getMisunderstandingsRequest ( filters: StatisticsFilters ): Promise<any> {
        try {
            delete filters.botId;
            const response = await axiosInstance.get(
                "/api/stats-v2/stats/misunderstandings",
                {
                    params: filters,
                }
            );
            return response.data;
        } catch ( error: any ) {
            throw new Error( "No Data Available" ); 
        }
    }

    async getTotalSatisfactionRequest ( filters: StatisticsFilters ): Promise<any> {
        try {
            const response = await axiosInstance.get( "/api/stats-v2/stats/tagCount", {
                params: filters,
            });
            return response.data;
        } catch ( error: any ) {
            throw new Error( "No Data Available" ); 
        }
    }
    async getTransfersNumberRequest ( filters: StatisticsFilters ): Promise<any> {
        try {
            const response = await axiosInstance.get(
                "/api/stats-v2/stats/conversations/transfers",
                {
                    params: filters,
                }
            );
            return response.data;
        } catch ( error: any ) {
            throw new Error( "No Data Available" ); 
        }
    }
    async getMesageDistributionRequest ( filters: StatisticsFilters ): Promise<any> {
        try {
            delete filters.botId;
            const response = await axiosInstance.get(
                "/api/stats-v2/stats/messages/distribution",
                {
                    params: filters,
                }
            );
            return response.data;
        } catch ( error: any ) {
            throw new Error( "No Data Available" ); 
        }
    }
    async getUniqueUsersRequest ( filters: StatisticsFilters ): Promise<any> {
        try {
            const response = await axiosInstance.get(
                "/api/stats-v2/stats/users/unique",
                {
                    params: filters,
                }
            );
            return response.data;
        } catch ( error: any ) {
            throw new Error( "No Data Available" ); 
        }
    }
    async getWeeklyConversationsRequest (
        filters: StatisticsFilters
    ): Promise<any> {
        try {
            const response = await axiosInstance.get(
                "/api/stats-v2/stats/conversations/average-by-weekday",
                {
                    params: filters,
                }
            );
            return response.data;
        } catch ( error: any ) {
            throw new Error( "No Data Available" ); 
        }
    }
}