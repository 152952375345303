import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isoWeek from "dayjs/plugin/isoWeek";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend( utc );
dayjs.extend( isoWeek );
dayjs.extend( weekOfYear );
dayjs.extend( isSameOrAfter );
dayjs.extend( isSameOrBefore );
dayjs.locale( "fr" );
export default dayjs;