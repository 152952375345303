import React, { useEffect } from "react";
import FrameInfo from "../FrameInfo";
import { getTransfersNumber } from "clean-archi/core/use-cases/statistics/transfers-number/getTrasfersNumberThunk";
import useFrameInfo from "../../hooks/framInfoHook";
import textCodes from "legacy/constants/translations/textCodes.json";
import { TranslationManager } from "translation-manager";
import { kpiColors } from "../../config/chartsConfig";

const TransfersNumber = () => {
    const { dispatch,ComponentsError, frameInfoStoreData, selectedConfiguration, statsFilters } =
    useFrameInfo({ frameInfoProp: "TransfersNumber" });

    const infoNumberOfTransfers = {
        title: TranslationManager.getText( textCodes.NUMBER_OF_TRANSFERS ),
        number: frameInfoStoreData ? frameInfoStoreData.toString() : "0",
        // percentage: "4%",
        icon: "las la-cube",
        iconColor: kpiColors.iconColor,
        iconBg: kpiColors.iconBg,
        errorType: ComponentsError["transfersNumberError"],
    };

    useEffect(() => {
        dispatch(
            getTransfersNumber({
                startDate: statsFilters.startDate,
                endDate: statsFilters.endDate,
                botInstanceId: selectedConfiguration.instanceId,
                branchName: statsFilters.branchName,
                perDay: true,
                ...( statsFilters.channelId ? {channelId: statsFilters.channelId} : {})
            })
        );
    }, [dispatch, statsFilters.branchName, selectedConfiguration.instanceId, statsFilters.endDate, statsFilters.startDate, statsFilters.channelId]);

    return <FrameInfo {...infoNumberOfTransfers} />;
};
export default TransfersNumber;
