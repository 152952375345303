import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { getConversationCount } from "clean-archi/core/use-cases/statistics/conversation-count/getConversationCountThunk";
import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import ChartSkeleton from "./skeletons/ChartSkeleton";
import { ConversationsCountWithPreviousApiResponse } from "clean-archi/core/entities/statistics/StatisticsState";
import { getConversationsChartOptions } from "../config/chartsConfig";
import textCodes from "legacy/constants/translations/textCodes.json";
import { TranslationManager } from "translation-manager";
import GraphErrorToast from "./GraphErrorToast";

const Conversations = () => {
    const conversationCountLoading: boolean = useAppSelector(
        ( state ) => state.stats.conversationCountLoading
    );
    const dispatch = useAppDispatch();
    const selectedConfiguration = useAppSelector(
        ( state ) => state.app.selectedConfiguration
    );
    const statsFilters = useAppSelector(( state ) => state.stats.filters );
    const ConversationsStoreData = useAppSelector(
        ( state ) => state.stats.conversationsCount
    );
 
    const ComponentsError = useAppSelector(
        ( state ) => state.stats.componentsError
    );
    
    useEffect(() => {
        dispatch(
            getConversationCount({
                startDate: statsFilters.startDate,
                endDate: statsFilters.endDate,
                botInstanceId: selectedConfiguration.instanceId,
                branchName: statsFilters.branchName,
                perDay: true,
                isCurrentAndPrevious: true,
                ...( statsFilters.channelId ? {channelId: statsFilters.channelId} : {})
            })
        );
    }, [dispatch, selectedConfiguration.instanceId, statsFilters.branchName, statsFilters.startDate, statsFilters.endDate, statsFilters.channelId]);
    const options = getConversationsChartOptions(
        ConversationsStoreData as ConversationsCountWithPreviousApiResponse,
        statsFilters.startDate,
        statsFilters.endDate
    );
    if ( conversationCountLoading ) return <ChartSkeleton />;

    return (
        <div className="p-3">
            <div className="d-flex justify-content-between align-items-center">
                <span className="chart-title">
                    {TranslationManager.getText( textCodes.CONVERSATIONS )}
                </span>
                {ComponentsError.conversationsCountError && (
                    <GraphErrorToast error={ComponentsError.errorMessage} />
                )}
            </div>
            {!ComponentsError.conversationsCountError && (
                <Chart
                    options={options}
                    series={options.series}
                    type="line"
                    height={300}
                />
            )}
        </div>
    );
};

export default Conversations;
