import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StatisticsFilters } from "clean-archi/core/entities/statistics/StatisticsFilters";
import {
    ConversationsCountApiResponse,
    StatisticsState,
} from "clean-archi/core/entities/statistics/StatisticsState";
import { getConversationDuration } from "clean-archi/core/use-cases/statistics/conversation-duration/getConversationDurationThunk";
import { getConversationCount } from "clean-archi/core/use-cases/statistics/conversation-count/getConversationCountThunk";
import { getTransfersNumber } from "clean-archi/core/use-cases/statistics/transfers-number/getTrasfersNumberThunk";
import { getUniqueUsers } from "../../core/use-cases/statistics/unique-users/getUniqueUsersThunk";
import { getMisunderstanding } from "clean-archi/core/use-cases/statistics/misunderstanding/getMisunderstandingThunk";
import { getMesageDistribution } from "clean-archi/core/use-cases/statistics/message-distribution/getMessageDistributionThunk";
import { getTotalConversations } from "clean-archi/core/use-cases/statistics/getTotalConversations/getTotalConversationsThunk";
import { getOpeningsCount } from "clean-archi/core/use-cases/statistics/openings-count/getOpeningsCountThunk";
import { getChannels } from "clean-archi/core/use-cases/statistics/getChannels/getChannelsThunk";
import dayjs from "clean-archi/dayjs";
import { getWeeklyConversations } from "clean-archi/core/use-cases/statistics/weekly-conversations/getWeeklyConversationsThunk";
import { getConversationPerHour } from "clean-archi/core/use-cases/statistics/getConversationsPerHour/getConversationPerHourThunk";

const defaultFilters: Omit<StatisticsFilters, "botId"> = {
    branchName: "prod",
    startDate: dayjs
        .utc( dayjs().format( "YYYY-MM-DD 00:00:00" ))
        .subtract( 7, "day" )
        .toDate(),
    endDate: dayjs.utc( dayjs().format( "YYYY-MM-DD 00:00:00" )).toDate(),
    botInstanceId: "",
    channelExternalId: "",
    channelId: "",
    channelType: "",
    perDay: false,
    perMonth: false,
    perYear: false,
};
 
const initialState: StatisticsState = {
    filters: defaultFilters,
    componentsError: { conversationsCountError: false, errorMessage: "" },   
    
    averageConversationsDuration: [],
    conversationDurationLoading: true,

    transfersNumber: [],
    transfersNumberLoading: true,

    conversationsCount: [],
    conversationCountLoading: true,

    totalConversations: [],
    totalConversationsLoading: true,

    uniqueUsers: [],
    uniqueUsersLoading: true,

    misunderstanding: [],
    misunderstandingLoading: true,

    messagesDistribution: [],
    messagesDistributionLoading: true,

    weeklyConversations: [],
    weeklyConversationsLoading: false,

    openingsCount: {
        data: {
            total: 0,
            evolutionRate: undefined,
        },
    },
    openingsCountLoading: true,

    conversationsPerHour: [],
    conversationsPerHourLoading: true,

    channels: [],
    channelsLoading: true,

    totalSatisfaction: null,
};

export const statisticsSlice = createSlice({
    name: "stats",
    initialState,
    reducers: {
        setFilters (
            state,
            action: PayloadAction<Partial<Omit<StatisticsFilters, "botId">>>
        ) {
            state.filters = {
                ...state.filters,
                ...action.payload,
            };
        },
    },
    extraReducers: ( builder ) => {
        builder.addCase( getConversationDuration.pending, ( state ) => {
            state.conversationDurationLoading = true;
        });

        builder.addCase(
            getConversationDuration.fulfilled,
            ( state: StatisticsState, action: PayloadAction<any> ) => {
                state.averageConversationsDuration = action.payload;
                state.conversationDurationLoading = false;
            }
        );
        builder.addCase( getConversationDuration.rejected, ( state, action ) => {
            state.conversationDurationLoading = false;
            state.componentsError.conversationsDurationError = true;
            state.componentsError.errorMessage =
              action.payload?.message || "Something went wrong";
        });

        builder.addCase( getConversationCount.pending, ( state, action ) => {
            state.conversationCountLoading = true;
        });
        builder.addCase(
            getConversationCount.fulfilled,
            (
                state: StatisticsState,
                action: PayloadAction<ConversationsCountApiResponse[]>
            ) => {
                state.conversationsCount = action.payload;
                state.conversationCountLoading = false;
            }
        );
        builder.addCase( getConversationCount.rejected, ( state, action ) => { 
            state.conversationCountLoading = false;
            state.componentsError.conversationsCountError = true;
            state.componentsError.errorMessage =
              action.payload?.message || "Something went wrong";
        });

        builder.addCase( getTotalConversations.pending, ( state, action ) => {
            state.totalConversationsLoading = true;
        });
        builder.addCase(
            getTotalConversations.fulfilled,
            (
                state: StatisticsState,
                action: PayloadAction<ConversationsCountApiResponse[]>
            ) => {
                state.totalConversations = action.payload;
                state.totalConversationsLoading = false;
            }
        );
        builder.addCase( getTotalConversations.rejected, ( state, action ) => {
            state.totalConversationsLoading = false;

            state.componentsError.totalConversationsError = true; 
            state.componentsError.errorMessage =
              action.payload?.message || "Something went wrong";
        });

        builder.addCase( getTransfersNumber.pending, ( state, action ) => {
            state.transfersNumberLoading = true;
        });
        builder.addCase( getTransfersNumber.fulfilled, ( state, action ) => {
            state.transfersNumber = action.payload;
            state.transfersNumberLoading = false;
        });
        builder.addCase( getTransfersNumber.rejected, ( state, action ) => {
            state.transfersNumberLoading = false;
            state.componentsError.transfersNumberError = true;
            state.componentsError.errorMessage =
               action.payload?.message || "Something went wrong";
        });

        builder.addCase( getUniqueUsers.pending, ( state, action ) => {
            state.uniqueUsersLoading = true;
        });
        builder.addCase( getUniqueUsers.fulfilled, ( state, action ) => {
            state.uniqueUsers = action.payload;
            state.uniqueUsersLoading = false;
        });
        builder.addCase( getUniqueUsers.rejected, ( state, action ) => {
            state.uniqueUsersLoading = false;
            state.componentsError.uniqueUsersError = true;
            state.componentsError.errorMessage =
                          action.payload?.message || "Something went wrong";
        });

        builder.addCase( getMisunderstanding.pending, ( state, action ) => {
            state.misunderstandingLoading = true;
        });
        builder.addCase( getMisunderstanding.fulfilled, ( state, action ) => {
            state.misunderstanding = action.payload;
            state.misunderstandingLoading = false;
        });
        builder.addCase( getMisunderstanding.rejected, ( state, action ) => {
            state.misunderstandingLoading = false;
            state.componentsError.misunderstandingsError = true;
            state.componentsError.errorMessage =
              action.payload?.message || "Something went wrong";
        });

        builder.addCase( getMesageDistribution.pending, ( state, action ) => {
            state.messagesDistributionLoading = true;
        });
        builder.addCase( getMesageDistribution.fulfilled, ( state, action ) => {
            state.messagesDistribution = action.payload;
            state.messagesDistributionLoading = false;
        });
        builder.addCase( getMesageDistribution.rejected, ( state, action ) => {
            state.messagesDistributionLoading = false;
            state.componentsError.messageDistributionError = true;
            state.componentsError.errorMessage =
              action.payload?.message || "Something went wrong";
        });

        builder.addCase( getOpeningsCount.pending, ( state, action ) => {
            state.openingsCountLoading = true;
        });
        builder.addCase( getOpeningsCount.fulfilled, ( state, action ) => {
            state.openingsCount = action.payload;
            state.openingsCountLoading = false;
        });
        builder.addCase( getOpeningsCount.rejected, ( state, action ) => {
            state.openingsCountLoading = false;
            state.componentsError.openingsCountError = true;
            state.componentsError.errorMessage =
               action.payload?.message || "Something went wrong";
        });
        builder.addCase( getWeeklyConversations.pending, ( state ) => {
            state.weeklyConversationsLoading = true;
        });
        builder.addCase( getWeeklyConversations.fulfilled, ( state, action ) => {
            state.weeklyConversations = action.payload.data;
            state.weeklyConversationsLoading = false;
        });
        builder.addCase( getWeeklyConversations.rejected, ( state, action ) => {
            state.weeklyConversationsLoading = false;
            state.componentsError.weeklyConversationsError = true;
            state.componentsError.errorMessage =
              action.payload?.message || "Something went wrong";
        });

        builder.addCase( getConversationPerHour.pending, ( state, action ) => {
            state.conversationsPerHourLoading = true;
        });
        builder.addCase( getConversationPerHour.fulfilled, ( state, action ) => {
            state.conversationsPerHour = action.payload;
            state.conversationsPerHourLoading = false;
        });
        builder.addCase( getConversationPerHour.rejected, ( state, action ) => {
            state.conversationsPerHourLoading = false;
            state.componentsError.conversationsPerHourError = true;
            state.componentsError.errorMessage = action.payload?.message || "Something went wrong";
        });
        builder.addCase( getChannels.pending, ( state, action ) => {
            state.channelsLoading = true;
        });
        builder.addCase( getChannels.fulfilled, ( state, action ) => {
            state.channels = action.payload;
            state.channelsLoading = false;
        });
        builder.addCase( getChannels.rejected, ( state, action ) => {
            state.channelsLoading = false;
            state.componentsError.channelsError = true;
            state.componentsError.errorMessage = action.payload?.message || "Something went wrong";
        }); 
    },
});

export const { setFilters } = statisticsSlice.actions;

export default statisticsSlice.reducer;
