import React, { useEffect } from "react";
import { TranslationManager } from "translation-manager";
import { getConversationDuration } from "clean-archi/core/use-cases/statistics/conversation-duration/getConversationDurationThunk";
import FrameInfo, { FrameInfoProps } from "../FrameInfo";
import useFrameInfo from "../../hooks/framInfoHook";
import { formatDuration } from "clean-archi/store/utils/helpers";
import textCodes from "legacy/constants/translations/textCodes.json";
import { kpiColors } from "../../config/chartsConfig";

const ConversationDuration = () => {
    const { dispatch,ComponentsError, frameInfoStoreData, selectedConfiguration, statsFilters } =
    useFrameInfo({ frameInfoProp: "ConversationDuration" });

    useEffect(() => {
        dispatch(
            getConversationDuration({
                startDate: statsFilters.startDate,
                endDate: statsFilters.endDate,
                botInstanceId: selectedConfiguration.instanceId,
                branchName: statsFilters.branchName, 
                ...( statsFilters.channelId ? {channelId: statsFilters.channelId} : {}) 
            })
        );
    }, [dispatch, statsFilters.branchName, selectedConfiguration.instanceId, statsFilters.endDate, statsFilters.startDate, statsFilters.channelId]);

    // TODO: replace the percentage and the positiveEvolution with real data from api
    const infoConversationDuration: FrameInfoProps = {
        title: TranslationManager.getText(
            textCodes.AVERAGE_CONVERSATION_DURATION
        ),
        number: `${
            frameInfoStoreData ? formatDuration( frameInfoStoreData ) : "0s"
        }`,
        // percentage: "1.8%",
        icon: "las la-history",
        iconColor: kpiColors.iconColor,
        iconBg: kpiColors.iconBg,
        errorType: ComponentsError["conversationsDurationError"],
    };
    return <FrameInfo {...infoConversationDuration} />;
};
export default ConversationDuration;
