import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { getMesageDistribution } from "clean-archi/core/use-cases/statistics/message-distribution/getMessageDistributionThunk";
import ChartSkeleton from "./skeletons/ChartSkeleton";
import { MessageDistributionApiResponse } from "clean-archi/core/entities/statistics/StatisticsState";
import useStatsData from "../hooks/graphsHook";
import {
    getMessageDistributionData,
    getMessageDistributionOptions,
} from "../config/chartsConfig";
import textCodes from "legacy/constants/translations/textCodes.json";
import { TranslationManager } from "translation-manager";
import GraphErrorToast from "./GraphErrorToast";

const MesageDistributionGraph = () => {
    const { storeData, loading, statsFilters, selectedConfiguration, dispatch, ComponentsError } =
    useStatsData({
        dataSelector: ( state ) => state.stats.messagesDistribution,
        loadingSelector: ( state ) => state.stats.messagesDistributionLoading,
    });

    useEffect(() => {
        dispatch(
            getMesageDistribution({
                startDate: statsFilters.startDate,
                endDate: statsFilters.endDate,
                botInstanceId: selectedConfiguration.instanceId,
                branchName: statsFilters.branchName,
                ...( statsFilters.channelId ? {channelId: statsFilters.channelId} : {})
            })
        );
    }, [dispatch, selectedConfiguration.instanceId, statsFilters.branchName, statsFilters.startDate, statsFilters.endDate, statsFilters.channelId]);

    const dataPie = getMessageDistributionData(
    storeData as MessageDistributionApiResponse[]
    );
    const labels = dataPie.map(( data ) => data.item );
    const options = getMessageDistributionOptions( labels );
    const series = dataPie.map(( item ) => item.value );

    if ( loading ) return <ChartSkeleton />;

    return (
        <div className="p-3">
            <div className="d-flex justify-content-between align-items-center"> 
                <span className="chart-title">
                    {TranslationManager.getText( textCodes.MESSAGE_DISTRIBUTION )}
                </span>
                {ComponentsError.messageDistributionError && (
                    <GraphErrorToast error={ComponentsError.errorMessage} />
                )}
            </div>
            <Chart options={options} series={series} type="pie" height={200} />
        </div>
    );
};

export default MesageDistributionGraph;
