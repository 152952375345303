/* eslint-disable no-unused-vars */
export interface StatisticsFilters {
  // TODO - since we are working with stats we have to select Prod as branch name
  branchName?: string; // "prod" || "develop"
  botInstanceId?: string;
  startDate?: Date;
  endDate?: Date;
  botId?: string;
  channelExternalId?: string;
  channelId?: string;
  channelType?: string;
  perDay?: boolean;
  perMonth?: boolean;
  perYear?: boolean;
  isCurrentAndPrevious?: boolean;
}

export enum BranchNameEnum {
  DEV = "develop",
  PROD = "prod",
}
