import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { getUniqueUsers } from "clean-archi/core/use-cases/statistics/unique-users/getUniqueUsersThunk";
import ChartSkeleton from "./skeletons/ChartSkeleton";
import useStatsData from "../hooks/graphsHook";
import {
    getUniqueUsersSeries,
    getUniqueUsersOptions,
} from "../config/chartsConfig";
import { UniqueUsersApiResponse } from "clean-archi/core/entities/statistics/StatisticsState";
import textCodes from "legacy/constants/translations/textCodes.json";
import { TranslationManager } from "translation-manager";
import GraphErrorToast from "./GraphErrorToast";

const UniqueUsers = () => {
    const { storeData, loading, statsFilters, selectedConfiguration, dispatch, ComponentsError } =
    useStatsData({
        dataSelector: ( state ) => state.stats.uniqueUsers,
        loadingSelector: ( state ) => state.stats.uniqueUsersLoading,
    });

    useEffect(() => {
        dispatch(
            getUniqueUsers({
                startDate: statsFilters.startDate,
                endDate: statsFilters.endDate,
                botInstanceId: selectedConfiguration.instanceId,
                branchName: statsFilters.branchName,
                ...( statsFilters.channelId ? {channelId: statsFilters.channelId} : {})
            })
        );
    }, [
        dispatch,
        selectedConfiguration.instanceId,
        statsFilters.branchName,
        statsFilters.startDate,
        statsFilters.endDate,
        statsFilters.channelId,
    ]);

    const { series, labels } = getUniqueUsersSeries(
    storeData as UniqueUsersApiResponse[]
    );
    const options = getUniqueUsersOptions( labels, series );

    if ( loading ) return <ChartSkeleton />;

    return (
        <div className="p-3">
            <div className="d-flex justify-content-between align-items-center">
                <span className="chart-title">
                    {TranslationManager.getText( textCodes.UNIQUE_USERS )}
                </span>
                {ComponentsError.uniqueUsersError && (
                    <GraphErrorToast error={ComponentsError.errorMessage} />
                )}
            </div>
            {!ComponentsError.uniqueUsersError && (
                <Chart height={200} options={options} series={series} type="donut" />
            )}
        </div>
    );
};
export default UniqueUsers;
