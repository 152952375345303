import React, { useEffect } from "react";
import { kpiColors } from "../../config/chartsConfig";
import useFrameInfo from "../../hooks/framInfoHook";
import FrameInfo from "../FrameInfo";
import { TranslationManager } from "translation-manager";
import textCodes from "legacy/constants/translations/textCodes.json";
import { getOpeningsCount } from "clean-archi/core/use-cases/statistics/openings-count/getOpeningsCountThunk";

const OpeningsNumber = () => {
    const { dispatch, ComponentsError, frameInfoStoreData, evolutionRate, selectedConfiguration, statsFilters } =
    useFrameInfo({ frameInfoProp: "openingsCount" });

    const infoNumberOfTransfers = {
        title: TranslationManager.getText( textCodes.NUMBER_OF_OPENINGS ),
        number: frameInfoStoreData ? frameInfoStoreData.toString() : "0",
        percentage: evolutionRate * 100,
        icon: "las la-cube",
        iconColor: kpiColors.iconColor,
        iconBg: kpiColors.iconBg,
        errorType: ComponentsError["openingsCountError"],
    };
    useEffect(() => {
        dispatch(
            getOpeningsCount({
                startDate: statsFilters.startDate,
                endDate: statsFilters.endDate,
                botInstanceId: selectedConfiguration.instanceId,
                branchName: statsFilters.branchName,
                ...( statsFilters.channelId ? {channelId: statsFilters.channelId} : {})
            })
        );
    }, [dispatch, statsFilters.branchName, selectedConfiguration.instanceId, statsFilters.endDate, statsFilters.startDate, statsFilters.channelId]);

    return <FrameInfo {...infoNumberOfTransfers} />;
};
export default OpeningsNumber;