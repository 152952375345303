import React from "react";
import FrameInfoSkeleton from "./skeletons/FrameInfoSkeleton";
import { useAppSelector } from "legacy/store/typedHooks";
import GraphErrorToast from "./GraphErrorToast";
import { ComponentsError } from "clean-archi/core/entities/statistics/StatisticsState";
 
// FrameInfoProps interface
export interface FrameInfoProps {
  title: string;
  number?: string;
  percentage?: number;
  icon: string;
  iconColor: string;
  iconBg: string;
  errorType: Omit<ComponentsError, "errorMessage">;
}

function FrameInfo ( info: FrameInfoProps ) {
    const conversationCountLoading = useAppSelector(
        ( state ) => state.stats.conversationCountLoading
    );
    const conversationDurationLoading = useAppSelector(
        ( state ) => state.stats.conversationDurationLoading
    );
    const transfersNumberLoading = useAppSelector(
        ( state ) => state.stats.transfersNumberLoading
    );
    const totalConversationsLoading = useAppSelector(
        ( state ) => state.stats.transfersNumberLoading
    );
    const uniqueUsersLoading = useAppSelector(
        ( state ) => state.stats.transfersNumberLoading
    );
    const misunderstandingLoading = useAppSelector(
        ( state ) => state.stats.transfersNumberLoading
    );
    const messagesDistributionLoading = useAppSelector(
        ( state ) => state.stats.transfersNumberLoading
    );
    const weeklyConversationsLoading = useAppSelector(
        ( state ) => state.stats.transfersNumberLoading
    );
    const openingsCountLoading = useAppSelector(
        ( state ) => state.stats.transfersNumberLoading
    );
    const ComponentsError = useAppSelector(
        ( state ) => state.stats.componentsError
    );

    // Check for loading states
    if (
        conversationCountLoading ||
    conversationDurationLoading ||
    transfersNumberLoading ||
    totalConversationsLoading ||
    uniqueUsersLoading ||
    misunderstandingLoading ||
    messagesDistributionLoading ||
    weeklyConversationsLoading ||
    openingsCountLoading
    ) {
        return <FrameInfoSkeleton />;
    }

    // Check if the specific error exists in ComponentsError
    const showError = info.errorType;
    console.log( "showError  : ", showError );
    info.errorType && console.log( info.errorType );
    console.log( ComponentsError );

    var arrowIcon;
    var arrowClass;

    if ( info.percentage ) {
        if ( info.percentage >= 0 ) {
            arrowClass = "positive-evolution";
            arrowIcon = "las la-arrow-circle-up";
        } else {
            arrowClass = "negative-evolution";
            arrowIcon = "las la-arrow-circle-down";
        }
    }

    return (
        <div className="frame-info">
            <div className="flex d-flex align-items-center space-between mb-3">
                <span className="chart-title">{info.title}</span>
                <div
                    style={{ backgroundColor: info.iconBg }}
                    className="frame-info-icon"
                >
                    <i style={{ color: info.iconColor }} className={info.icon}></i>
                </div>
            </div>

            {showError ? ( // Display error toast for the specific component
                <GraphErrorToast error={ComponentsError.errorMessage} />
            ) : (
                <div className="flex space-between">
                    <span className="card-number">{info?.number}</span>
                    {info.percentage && !Number.isNaN( info.percentage ) && (
                        <div className="flex align-items-center gap-2">
                            <i className={`${arrowIcon} ${arrowClass} arrow-icon`}></i>
                            <span className={`percentage ${arrowClass}`}>{`${info.percentage.toFixed( 2 )} %`}</span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default FrameInfo;
