import React, { useEffect } from "react";
import FrameInfo from "../FrameInfo";
import useFrameInfo from "../../hooks/framInfoHook";
import textCodes from "legacy/constants/translations/textCodes.json";
import { TranslationManager } from "translation-manager";
import { kpiColors } from "../../config/chartsConfig";
import { getTotalConversations } from "clean-archi/core/use-cases/statistics/getTotalConversations/getTotalConversationsThunk";
 
const TotalInteractions = () => {
    const { dispatch,ComponentsError, frameInfoStoreData, selectedConfiguration, statsFilters } =
    useFrameInfo({ frameInfoProp: "TotalInteractions" });

    useEffect(() => {
        dispatch(
            getTotalConversations({
                startDate: statsFilters.startDate,
                endDate: statsFilters.endDate,
                botInstanceId: selectedConfiguration.instanceId,
                branchName: statsFilters.branchName,
                ...( statsFilters.channelId ? {channelId: statsFilters.channelId} : {}) 
            })
        );
    }, [dispatch, selectedConfiguration.instanceId, statsFilters.branchName, statsFilters.startDate, statsFilters.endDate, statsFilters.channelId]);

    // TODO: replace the percentage and the positiveEvolution with real data from api
    const infoTotalInteraction = {
        title: TranslationManager.getText( textCodes.TOTAL_INTERACTIONS ),
        number: frameInfoStoreData ? frameInfoStoreData.toString() : "0",
        // percentage: "8.5%",
        icon: "las la-user-tag",
        iconColor: kpiColors.iconColor,
        iconBg: kpiColors.iconBg,
        errorType: ComponentsError["totalConversationsError"],
    };

    return <FrameInfo {...infoTotalInteraction} />;
};

export default TotalInteractions;
