import React from "react";
import { Alert } from "react-bootstrap";
 
const GraphErrorToast = ({ error }: { error: string }) => {
    return (
        <Alert key={"dark"} variant={"dark"} className="rounded mb-0 text-dark">
            <span className="me-auto"> {error} </span>
        </Alert>
         
    );
              
};
export default GraphErrorToast;