import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { getConversationsPerHourChartOptions, getConversationsPerHourChartSeries } from "../config/chartsConfig";
import { getConversationPerHour } from "clean-archi/core/use-cases/statistics/getConversationsPerHour/getConversationPerHourThunk";
import useStatsData from "../hooks/graphsHook";
import { ConversationsPerHourApiResponse } from "clean-archi/core/entities/statistics/StatisticsState";
import textCodes from "legacy/constants/translations/textCodes.json";
import { TranslationManager } from "translation-manager";
import ChartSkeleton from "./skeletons/ChartSkeleton";
import { Toast } from "react-bootstrap";
import GraphErrorToast from "./GraphErrorToast";

const ConversationsPerHour = () => {
    const { storeData, loading, statsFilters, selectedConfiguration, dispatch, ComponentsError } =
   useStatsData({
       dataSelector: ( state ) => state.stats.conversationsPerHour,
       loadingSelector: ( state ) => state.stats.conversationsPerHourLoading,
   });
    useEffect(() => {
        dispatch(
            getConversationPerHour({
                startDate: statsFilters.startDate,
                endDate: statsFilters.endDate,
                botInstanceId: selectedConfiguration.instanceId,
                branchName: selectedConfiguration.branchName, 
            })
        );
    }, [dispatch, selectedConfiguration.instanceId, selectedConfiguration.branchName, statsFilters.startDate, statsFilters.endDate]);

    const options = getConversationsPerHourChartOptions(
      storeData as ConversationsPerHourApiResponse[]
    );
    const series = getConversationsPerHourChartSeries(
      storeData as ConversationsPerHourApiResponse[]
    );

    if ( loading ) return <ChartSkeleton />;

    return (
        <>
            <div className="p-3">
                <div className="d-flex justify-content-between align-items-center">
                    <span className="chart-title">
                        {TranslationManager.getText( textCodes.CONVERSATIONS_PER_HOUR )}
                    </span>
                    {ComponentsError.conversationsPerHourError && (
                        <GraphErrorToast error={ComponentsError.errorMessage} />
                    )}
                </div>
                {!ComponentsError.conversationsPerHourError && (
                    <Chart options={options} series={series} type="bar" height={300} />
                )}
            </div>
        </>
    );
};

export default ConversationsPerHour;
