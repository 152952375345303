import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
const useFrameInfo = ({ frameInfoProp }: { frameInfoProp: string }) => {
    const dispatch = useAppDispatch();
    const selectedConfiguration = useAppSelector(
        ( state ) => state.app.selectedConfiguration
    );
    const statsFilters = useAppSelector(( state ) => state.stats.filters );

    const conversationDurationData = useAppSelector(
        ( state ) => state.stats.averageConversationsDuration[0]
    );
    const totalInteractionsData = useAppSelector(
        ( state ) => state.stats.totalConversations[0]
    );
    const transfersNumberData = useAppSelector(
        ( state ) => state.stats.transfersNumber
    );
    const openingsCountData = useAppSelector(
        ( state ) => state.stats.openingsCount
    );
    const ComponentsError = useAppSelector(( state ) => state.stats.componentsError );
    let frameInfoStoreData, evolutionRate;

    switch ( frameInfoProp ) {
        case "ConversationDuration":
            frameInfoStoreData =
        conversationDurationData?.averageConversationsDuration;
            break;
        case "TotalInteractions": {
            frameInfoStoreData = totalInteractionsData
                ? totalInteractionsData.conversationsCount
                : 0;
            break;
        }
        case "TransfersNumber": {
            let sum = 0;
            for ( let value of transfersNumberData ) {
                sum += value.transfersCount;
            }
            frameInfoStoreData = sum;
            break;
        }
        case "openingsCount": {
            frameInfoStoreData = openingsCountData ? openingsCountData.data.total : 0;
            evolutionRate = openingsCountData.data.evolutionRate;
            break;
        }
        default:
            frameInfoStoreData = 0;
            break;
    }

    return {
        dispatch,
        frameInfoStoreData,
        evolutionRate,
        selectedConfiguration,
        statsFilters,
        ComponentsError,
    };
};

export default useFrameInfo;
