import React, { useEffect } from "react"; 
import Select, { components } from "react-select";
import { findKey } from "lodash";
import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import { TranslationManager } from "translation-manager";
import textCodes from "legacy/constants/translations/textCodes.json";
import { setFilters } from "clean-archi/store/statistic";
import { BranchNameEnum } from "clean-archi/core/entities/statistics/StatisticsFilters";
import { channelsIcon, channelsCode } from "legacy/constants/hmbConstants";
import { getChannels } from "clean-archi/core/use-cases/statistics/getChannels/getChannelsThunk";
import SelectSkeletton from "../graphs/skeletons/SelectSkeletton";
import GraphErrorToast from "../graphs/GraphErrorToast";

const ChannelsFilter = () => {
    const dispatch = useAppDispatch();
    const environnementSelected = useAppSelector(
        ( state ) => state.stats.filters.branchName
    );
    const channels = useAppSelector(
        ( state ) => state.stats.channels 
    );
    const selectedConfiguration = useAppSelector(
        ( state ) => state.app.selectedConfiguration
    );
    const ComponentsError = useAppSelector(
        ( state ) => state.stats.componentsError
    );
    const channelsLoading = useAppSelector(( state ) => state.stats.channelsLoading );
    useEffect(() => {
        dispatch(
            getChannels({
                botInstanceId: selectedConfiguration.instanceId,
                branchName: environnementSelected
            })
        );
    }, [dispatch, environnementSelected, selectedConfiguration.instanceId]);

    const Option = ( props: any ) => {
        return (
            <components.Option {...props}>
                <span>
                    <span className="flex gap-2 align-items-center">
                        {props.data.icon}
                        {props.data.label}
                    </span>
                </span>
            </components.Option>
        );
    };
    const allChannelsOption = {
        label: TranslationManager.getText( textCodes.ALL ),
        value: "",
    };

    const ChannelsOptions = () => {
        let channelsFiltered;
        if ( process.env.REACT_APP_ENV !== "prod" ) {
            channelsFiltered = channels.filter(
                ( channel: any ) => channel.branchName === environnementSelected
            );
        } else {
            channelsFiltered = channels.filter(
                ( channel: any ) => channel.branchName === BranchNameEnum.PROD
            );
        }
        return [
            allChannelsOption,
            ...channelsFiltered.map(( channel: any ) => {
                const iconKey = findKey(
                    channelsCode,
                    ( channelCode ) => channelCode === channel.type
                ) as keyof typeof channelsIcon;
                return {
                    label: channel.name,
                    value: channel.id,
                    icon: iconKey ? channelsIcon[iconKey] : "",
                };
            }),
        ];
    };
   
    if ( ComponentsError && ComponentsError.channelsError ) { 
        return ( <GraphErrorToast error={ComponentsError.errorMessage} /> );
    }  
     
    return (
        <div className="filters-group d-flex flex-row align-items-center ">
            {channelsLoading ? (
                <SelectSkeletton />
            ) : (
                <Select
                    className="channel-dropdown"
                    isClearable={true}
                    isLoading={channelsLoading}
                    defaultValue={allChannelsOption}
                    placeholder={TranslationManager.getText( textCodes.CHANNEL_NAME )}
                    options={ChannelsOptions()}
                    components={{ Option }}
                    onChange={( option: any ) =>
                        dispatch(
                            setFilters({
                                channelId: option?.value,
                            })
                        )
                    }
                />
            )}
        </div>
    );
};
export default ChannelsFilter;