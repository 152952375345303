import React from "react";
import FrameInfo from "../FrameInfo";
import { TranslationManager } from "translation-manager";
import { useAppSelector } from "legacy/store/typedHooks";
import textCodes from "legacy/constants/translations/textCodes.json";
import { kpiColors } from "../../config/chartsConfig"; 

const TransfersRate = () => {

    const transfersNumberData = useAppSelector(( state ) => state.stats.transfersNumber );

    const ConversationsStoreData = useAppSelector(( state ) => state.stats.totalConversations );
    const ComponentsError = useAppSelector(( state ) => state.stats.componentsError
    );

    let transferNumberSum = 0;

    if ( transfersNumberData && Array.isArray( transfersNumberData )) {
        for ( let value of transfersNumberData ) {
            transferNumberSum += value.transfersCount || 0;
        }
    }

    const conversationCount = ConversationsStoreData[0]?.conversationsCount; 

    const transferRate = conversationCount > 0 ? ( transferNumberSum / conversationCount ) * 100 : 0;
    
    const infoTransferRate = {
        title: TranslationManager.getText( textCodes.TRANSFER_RATE ),
        number: transferRate ? `${transferRate.toFixed( 2 ) } %` : "0 %",
        icon: "las la-user-tag",
        iconColor: kpiColors.iconColor,
        iconBg: kpiColors.iconBg,
        errorType: ComponentsError["transferRateError"],
    };

    return <FrameInfo {...infoTransferRate} />;
};

export default TransfersRate;
