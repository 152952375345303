import { ApexOptions } from "apexcharts";
import {
    MessageDistributionApiResponse,
    MisunderstandingApiResponse,
    UniqueUsersApiResponse,
    WeeklyConversationsApiResponse,
    ConversationsCountWithPreviousApiResponse,
    ConversationsPerHourApiResponse,
} from "clean-archi/core/entities/statistics/StatisticsState";
import textCodes from "legacy/constants/translations/textCodes.json";
import { TranslationManager } from "translation-manager";

export const getMisunderstandingChartOptions = (
    data: MisunderstandingApiResponse[]
) => {
    const options: any = {
        chart: {
            type: "bar",
            height: 400,
            stacked: false,
        },
        plotOptions: {
            bar: {
                horizontal: false, 
                barHeight: "70%",
                rangeBarOverlap: true,
                dataLabels: {
                    position: "top",
                },
                columnWidth: "100%",
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function ( val: number ) {
                return `${val} %`;
            }, 
        },
        xaxis: {
            type: "datetime",
            labels: {
                format: "dd MMM", // Format des labels (ici jour + mois, ex: '01 Sep')
            },
            tickAmount: 10,
        },
        // xaxis: {
        //     max: 10,
        //     type: "category",
        //     categories: data.map(
        //         ( item ) => item.date && dayjs( item.date.split( "T" )[0]).format( "ddd D" ) // dates for x-axis
        //     ),

        //     labels: {
        //         style: {
        //             colors: "#000000",
        //             fontSize: "12px",
        //         },
        //     },
        // },
        tooltip: {
            shared: true,
            intersect: false,
        },
        legend: {
            position: "bottom",
        },
        colors: graphColors,
        zoom: {
            enabled: true,
            type: "x",
            resetIcon: {
                offsetX: -10,
                offsetY: 0,
                fillColor: "#fff",
                strokeColor: "#37474F",
            },
            selection: {
                background: "#90CAF9",
                border: "#0D47A1",
            },
        },
        toolbar: {
            show: false,
        },
    };
    return options;
};

// Misunderstanding seriesConfig.ts 
export const getMisunderstandingSeriesData = (
    data: MisunderstandingApiResponse[]
) => { 
    const aggregatedData = data.reduce<Record<string, MisunderstandingApiResponse>>(( acc, current ) => {
        const date = current.date;

        if ( acc[date]) {
            acc[date].acmFallbacksRate += current.acmFallbacksRate;
            acc[date].functionalFallbacksRate += current.functionalFallbacksRate;
            acc[date].nluFallbacksRate += current.nluFallbacksRate;
        } else {
            acc[date] = { ...current };
        }

        return acc;
    }, {});

    const accumulatedData: MisunderstandingApiResponse[] = Object.values( aggregatedData );
    return [
        {
            name: TranslationManager.getText( textCodes.ACM_FALLBACK ),
            data:
          data.length > 0
              ? accumulatedData.map(( item ) => ({
                  x: item.date,
                  y: item.acmFallbacksRate,
              }))
              : [],
        },
        /* {
            name: TranslationManager.getText( textCodes.LANGUAGE_FALLBACK ),
            data:
          data.length > 0
              ? data.map(( item ) => ({
                  x: item.date,
                  y: item.functionalFallbacksRate,
              }))
              : [],
        }, */
        {
            name: TranslationManager.getText( textCodes.NLU_FALLBACK ),
            data:
          data.length > 0
              ? data.map(( item ) => ({
                  x: item.date,
                  y: item.nluFallbacksRate,
              }))
              : [],
        },
    ];
};

export const getConversationsPerHourChartOptions = (
    data: ConversationsPerHourApiResponse[]
) => {
    const options: any = {
        chart: {
            type: "bar",
            height: 400,
        },
        plotOptions: {
            bar: {
                columnWidth: "95%", 
                dataLabels: {
                    position: "top", 
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function ( val: number ) {
                return val.toFixed( 0 ); 
            },
            style: {
                fontSize: "12px",
                colors: ["#000000"],
            },
        }, 
        xaxis: {
            categories: Array.from({ length: 24 }, ( v, k ) => `${k}:00 - ${k + 1}:00` ),  
            labels: {
                style: {
                    fontSize: "12px",
                    colors: "#000000",
                },
            }, 
        },
        yaxis: {
           
            labels: {
                style: {
                    fontSize: "12px",
                    colors: "#000000",
                },
            },
        },
        tooltip: {
            y: {
                formatter: function ( val: number ) {
                    return `${val} conversations`;
                },
            },
        },
    }; 
    return options;
};
 

export const getConversationsPerHourChartSeries = (
    data: ConversationsPerHourApiResponse[]
) => { 
    const series = [
        {
            name: TranslationManager.getText(
                textCodes.AVERAGE_CONVERSATION_PER_HOUR
            ),
            data:  data && data.map(( item ) => item.avg ),  
        },
    ]; 
    return series;
};
 

// messageDistributionChartConfig.ts
export const getMessageDistributionOptions = (
    labels: string[]
): ApexOptions => {
    const options: ApexOptions = {
        chart: {
            type: "pie",
        },
        labels: labels,
        colors: graphColors,
        dataLabels: {
            enabled: true,
            style: {
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: "bold",
            },
            dropShadow: {
                enabled: false,
            },
        },
    };
    return options;
};
export const getMessageDistributionData = (
    data: MessageDistributionApiResponse[]
) => [
    {
        item: TranslationManager.getText( textCodes.MESSAGES_WELL_HANDED ),
        value: data.length > 0 ? data[0].totalMessageCount : 0,
    },
    {
        item: TranslationManager.getText( textCodes.NLU_FALLBACK ),
        value: data.length > 0 ? data[0].totalNluFallbacksCount : 0,
    },
    {
        item: TranslationManager.getText( textCodes.FUNCTIONNAL_FALLBACK ),
        value: data.length > 0 ? data[0].totalAcmFallbacksCount : 0,
    },
];

export const kpiColors = {
    iconColor: "#15062F",
    iconBg: "#6199E7",
};

export const graphColors: string[] = [
    "#6199E7",
    "#FFC307",
    "#FE7837",
    "#10A51C",
    "#B67028",
    "#E4371B",
    "#A53FE0",
    "#282BC9",
    "#DA47C7",
    "#66D0D1",
];

export const graphLightColors: string[] = [
    "#83B0F7",
    "#FFEEB5",
    "#FEAA66",
    "#5ECC4B",
    "#B6874C",
    "#E46967",
    "#A58CDC",
    "#2854EB",
    "#E976EE",
    "#9AEDF4",
];

// uniqueUsersChartConfig.ts
export const getUniqueUsersOptions = ( labels: string[], series: number[]) => ({
    colors: graphColors,
    labels: labels,
    series: series,
    dataLabels: {
        enabled: false,
    },
});
// uniqueUsersSeriesConfig.ts
export const getUniqueUsersSeries = ( data: UniqueUsersApiResponse[]) => {
    const stateDataUpdated = data.length > 0 && data[0];
    return {
        series: stateDataUpdated ? Object.values( data[0]) : [],
        labels: stateDataUpdated ? Object.keys( data[0]) : [],
    };
};

function generateDaysList ( startDate: string, endDate: string ) {
    // Convertir les dates en objets Date
    let start = new Date( startDate );
    let end = new Date( endDate );

    // S'assurer que l'heure est réglée à minuit
    start.setUTCHours( 0, 0, 0, 0 );
    end.setUTCHours( 0, 0, 0, 0 );

    // Créer un tableau pour stocker les jours
    let daysList = [];

    // Boucle à travers chaque jour entre start et end
    while ( start <= end ) {
    // Ajouter la date en format ISO string à la liste
        daysList.push( new Date( start ).toISOString());

        // Passer au jour suivant
        start.setDate( start.getDate() + 1 );
    }

    return daysList;
}

function generatePreviousDaysList ( startDate: string, endDate: string ) {
    // Convertir les dates en objets Date
    let start = new Date( startDate );
    let end = new Date( endDate );

    // S'assurer que l'heure est réglée à minuit
    start.setUTCHours( 0, 0, 0, 0 );
    end.setUTCHours( 0, 0, 0, 0 );

    // Vérifier que les dates sont valides
    if ( isNaN( start.getTime()) || isNaN( end.getTime())) {
        throw new Error( "Invalid startDate or endDate" );
    }

    // Calculer la durée de la période en jours (toujours en type number)
    const duration = Math.round(
        ( end.getTime() - start.getTime()) / ( 1000 * 60 * 60 * 24 )
    );

    // Calculer les nouvelles dates de début et de fin pour la période précédente
    let previousEnd = new Date( start );
    previousEnd.setUTCDate( start.getUTCDate() - 1 );

    let previousStart = new Date( previousEnd );
    previousStart.setUTCDate( previousEnd.getUTCDate() - duration );

    // Créer la liste des jours pour cette période précédente
    return generateDaysList(
        previousStart.toISOString(),
        previousEnd.toISOString()
    );
}

// conversations linechart graph config
export const getConversationsChartOptions = (
    ConversationsStoreData: ConversationsCountWithPreviousApiResponse,
    startDate: string,
    endDate: string
): ApexOptions => {
    const currentDates = generateDaysList( startDate, endDate );
    const currentData: Record<string, number> = {};
    currentDates.map(( day ) => {
        if ( ConversationsStoreData.current ) {
            const items = ConversationsStoreData.current?.filter(
                ( item ) => item.date === day
            );
            if ( items ) {
                currentData[day] = items.reduce(
                    ( prev, current ) => prev + current.conversationsCount,
                    0
                );
            }
        }
    });
    const currentMappedData = Object.entries( currentData ).map(([key, value]) => ({
        date: key,
        conversationsCount: value,
    }));

    const previousDates = generatePreviousDaysList( startDate, endDate );
    const previousData: Record<string, number> = {};
    previousDates.map(( day ) => {
        if ( ConversationsStoreData.previous ) {
            const items = ConversationsStoreData.previous?.filter(
                ( item ) => item.date === day
            );
            if ( items ) {
                previousData[day] = items.reduce(
                    ( prev, current ) => prev + current.conversationsCount,
                    0
                );
            }
        }
    });
    const previousMappedData = Object.entries( previousData ).map(
        ([key, value]) => ({ date: key, conversationsCount: value })
    );
    return {
        series: [
            {
                name: TranslationManager.getText( textCodes.CURRENT_PERIOD ),
                data: currentMappedData.map(( item: any ) => ({
                    x: item.date,
                    y: item.conversationsCount,
                })),
            },
            {
                name: TranslationManager.getText( textCodes.PREVIOUS_PERIOD ),
                data: previousMappedData.map(( item: any, index ) => ({
                    x: currentMappedData[index]?.date,
                    y: item.conversationsCount,
                })),
            },
        ],
        chart: {
            type: "line",
            height: 200,
            // toolbar: {
            //     show: true,
            // },
            // zoom: {
            //     enabled: true,
            // },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: "bottom",
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        colors: graphColors,
        // plotOptions: {
        //     bar: {
        //         horizontal: false,
        //         borderRadius: 10,
        //         borderRadiusApplication: "end",
        //         borderRadiusWhenStacked: "last",
        //     },
        // },
        xaxis: {
            type: "datetime",
            labels: {
                format: "dd MMM", // Format des labels (ici jour + mois, ex: '01 Sep')
            },
            tickAmount: 10,
        },
        legend: {
            position: "top",
        },
        fill: {
            opacity: 1,
        },
        stroke: {
            curve: "smooth",
        },
    };
};
export const getWeeklyConversationsChartOptions = (
    data: WeeklyConversationsApiResponse[]
) => {
    const daysMatches : any = {
        "1": "Sunday",
        "2": "Monday",
        "3": "Tuesday",
        "4": "Wednesday",
        "5": "Thursday",
        "6": "Friday",
        "7": "Saturday",
    };
    const options: any = {
        chart: {
            type: "bar",
            height: 400,
            stacked: false, // Use `false` to ensure bars are not stacked
        },
        plotOptions: {
            bar: {
                horizontal: false, // Vertical bars
                barHeight: "100%",
                rangeBarOverlap: true, // Allow bars to overlap
                dataLabels: {
                    position: "top",
                },
                columnWidth: "20%",
            },
        },
        xaxis: {
            type: "category",
            categories: data.map( item => daysMatches[item.dayOfWeek]) ,

            labels: {
                style: {
                    colors: "#000000",
                    fontSize: "12px",
                },
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
        },
        legend: {
            position: "bottom",
        },
        colors: graphColors,
        zoom: {
            enabled: true,
            type: "x",
            resetIcon: {
                offsetX: -10,
                offsetY: 0,
                fillColor: "#fff",
                strokeColor: "#37474F",
            },
            selection: {
                background: "#90CAF9",
                border: "#0D47A1",
            },
        },
        toolbar: {
            show: false,
        },
    };
    return options;
};

export const getWeeklyConversationsSeriesData = (
    data: WeeklyConversationsApiResponse[]
) => [
    {
        data: data.map(( item ) => item.avg ),
    },
];
