import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { MisunderstandingApiResponse } from "clean-archi/core/entities/statistics/StatisticsState";
import { getMisunderstanding } from "clean-archi/core/use-cases/statistics/misunderstanding/getMisunderstandingThunk";
import ChartSkeleton from "./skeletons/ChartSkeleton";
import useStatsData from "../hooks/graphsHook";
import {
    getMisunderstandingChartOptions,
    getMisunderstandingSeriesData,
} from "../config/chartsConfig";
import textCodes from "legacy/constants/translations/textCodes.json";
import { TranslationManager } from "translation-manager";
import GraphErrorToast from "./GraphErrorToast";

const Misunderstanding = () => {
    const {
        storeData,
        loading,
        statsFilters,
        selectedConfiguration,
        dispatch,
        ComponentsError,
    } = useStatsData({
        dataSelector: ( state ) => state.stats.misunderstanding,
        loadingSelector: ( state ) => state.stats.misunderstandingLoading,
    });

    useEffect(() => {
        dispatch(
            getMisunderstanding({
                startDate: statsFilters.startDate,
                endDate: statsFilters.endDate,
                botInstanceId: selectedConfiguration.instanceId,
                branchName: statsFilters.branchName,
                ...( statsFilters.channelId ? {channelId: statsFilters.channelId} : {})
            })
        );
    }, [dispatch, selectedConfiguration.instanceId, statsFilters.branchName, statsFilters.startDate, statsFilters.endDate, statsFilters.channelId]);

    const options = getMisunderstandingChartOptions( storeData as MisunderstandingApiResponse[]);
    const series = getMisunderstandingSeriesData( storeData as MisunderstandingApiResponse[]);

    if ( loading ) {
        return <ChartSkeleton />;
    }
    return (
        <div className="p-3">
            <div className="d-flex justify-content-between align-items-center">
                <span className="chart-title">
                    {TranslationManager.getText( textCodes.MISUNDERSTANDINGS )}
                </span>
                {ComponentsError.misunderstandingsError && (
                    <GraphErrorToast error={ComponentsError.errorMessage} />
                )}
            </div>
            {!ComponentsError.misunderstandingsError && (
                <Chart options={options} series={series} type="bar" height={300} />
            )}
        </div>
    );
};

export default Misunderstanding;
